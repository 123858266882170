import React from "react"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Link } from "gatsby"

const SingleArchive = ({ pageContext }) => {
  const { node, next, previous } = pageContext
  return (
    <Layout>
      <GatsbySeo
        title={node.frontmatter.title}
        description="Archief van zwembadraad blijven spetteren"
      />
      <div className="container prose prose-lg prose-slate mx-auto max-w-5xl">
        <h2>{node.frontmatter.title}</h2>
        <div className="-mt-4 text-sm">{node.frontmatter.date}</div>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: node.html }}
        />
        <div className="flex justify-between">
          <div className="">
            {previous && (
              <Link className="text-link" to={previous.frontmatter.slug}>
                ← {previous.frontmatter.title}
              </Link>
            )}
            &nbsp;
          </div>
          <div className="">
            {next && (
              <Link className="text-link" to={next.frontmatter.slug}>
                {next.frontmatter.title} →
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SingleArchive
